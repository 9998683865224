/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum representing the status of a task.
 */
export enum StatusEnum {
  PENDING = "Pending",
  GETTING_ENTITIES = "Getting Entities",
  DEFINING_ENTITIES = "Defining Entities",
  EVALUATING_DIFFERENCES = "Evaluating Differences",
  CONSISTENCY_CHECK = "Consistency Check",
  DELETE_ENTITIES = "Delete Entities",
  CREATE_ENTITIES = "Create Entities",
  UPDATE_ENTITIES = "Update Entities",
  COMPLETED = "Completed",
  RETRY = "Retry",
  ERROR = "Error",
  NOT_FOUND = "Not Found",
}
