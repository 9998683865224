import { Badge, Card, Text } from "@mantine/core";
import type { Tenant } from "../../../generated/models/Tenant";

interface DisplayTenantProps {
  tenant: Tenant;
}

const DisplayTenant = ({ tenant }: DisplayTenantProps) => {
  const renderField = (label: string, value: string | number | undefined) => (
    <Text>
      <strong>{label}:</strong>{" "}
      {value !== undefined && value !== null ? value : "-"}
    </Text>
  );

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Badge color="blue" variant="light" mb="sm">
        {tenant.state}
      </Badge>
      {renderField("Identifier", tenant.identifier)}
      {renderField("ID", tenant.id)}
      {renderField("Tenant Label", tenant.label)}
      {renderField("Allowed IPs", tenant.allowed_ips?.join(", "))}
      {renderField("Retention Days", tenant.retention_days)}
      {renderField("Resolution", tenant.resolution)}
      {renderField("Train Distance", tenant.train_distance)}
      {renderField("Data Schema", tenant.data_schema)}
      {tenant.dashboard_config && (
        <>
          {renderField("Dashboard Label", tenant.dashboard_config.label)}
          {renderField(
            "State View Fraction",
            tenant.dashboard_config.state_view_fraction,
          )}
        </>
      )}
    </Card>
  );
};

export default DisplayTenant;
