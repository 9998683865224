import {
  Button,
  Group,
  Loader,
  Paper,
  Pill,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import {
  ApiError,
  BackendClient,
  ComparisonOperator,
  EntityEnum,
  LogicalOperator,
} from "../../../generated";
import { RuleLogicType } from "../../../types/state.type";
import { SelectCreatable } from "../../Controls/SelectCreatable";
import HandleRuleLogic from "../HandleRuleLogic";
import style from "./UpdateRuleProcessor.module.css";

const UpdateRuleProcessor = () => {
  const apiClient = useApiClient();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [selectedTenant, setSelectedTenant] = useState<string | null>("");
  const [tenantRuleIds, setTenantRuleIds] = useState<string[]>([]);
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);
  const [isRuleManuallyEdited, setIsRuleManuallyEdited] =
    useState<boolean>(false);

  const [ruleLogic, ruleLogicHandler] = useListState<RuleLogicType>([]);

  const {
    data,
    error,
    isLoading: isLoadingTenants,
  } = useSWR(["fetchAlltenants"], () => fetchAlltenants(apiClient));

  const ts_sc_cache_key = [
    "fetchTimeSeriesAndStructuralComponents",
    selectedTenant,
  ];
  const { data: timeSeriesAndStructuralData } = useSWR(ts_sc_cache_key, () =>
    fetchTimeSeries(apiClient, selectedTenant),
  );
  const timeseries =
    timeSeriesAndStructuralData?.Timeseries?.map((ts) => ts.identifier || "") ||
    [];

  const rules_cache_key = ["fetchTenantRules", selectedTenant];
  const { data: tenantRules, isLoading: isLoadingRules } = useSWR(
    rules_cache_key,
    () => fetchTenantRules(apiClient, selectedTenant),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
    },
  );

  const resetRule = () => {
    setSelectedRuleId("");
    ruleLogicHandler.setState([]);
    setIsRuleManuallyEdited(false);
  };

  const updateRule = async () => {
    const timeseriesList = ruleLogic.map((rl) => rl.timeSeries);
    const operatorList = ruleLogic.map((rl) => rl.comparisonOperator);
    const thresholdList = ruleLogic.map((rl) => rl.threshold);
    const booleanList = ruleLogic
      .map((rl) => rl.operator)
      .filter(Boolean) as LogicalOperator[];
    try {
      if (!selectedTenant) throw new Error("Tenant is required");
      if (!selectedRuleId) throw new Error("Rule identifier is required");
      setIsSaving(true);
      await apiClient.rule.upsertRuleTenantsTenantIdRulesRuleIdPut(
        selectedTenant,
        selectedRuleId,
        timeseriesList,
        operatorList,
        thresholdList,
        booleanList,
      );
      setIsRuleManuallyEdited(false);
      toast.success("Rule updated successfully!");
    } catch (error: unknown) {
      let message = "Failed to update rule processor: ";
      if (error instanceof ApiError) {
        message += JSON.stringify(error.body.detail);
      } else {
        message += error;
      }
      toast.error(message);
    } finally {
      resetRule();
      mutate(rules_cache_key);
      setIsSaving(false);
    }
  };

  const deleteRule = async (ruleId: string) => {
    if (!window.confirm(`Are you sure you want to delete rule ${ruleId}?`))
      return;
    try {
      if (!selectedTenant) throw new Error("Tenant is required");
      if (!ruleId) throw new Error("Rule identifier is required");
      setIsDeleting(true);
      await apiClient.rule.deleteRuleTenantsTenantIdRulesRuleIdDelete(
        selectedTenant,
        ruleId,
      );
      setTenantRuleIds(tenantRuleIds.filter((id) => id !== ruleId));
      toast.success("Rule deleted successfully!");
      mutate(rules_cache_key);
    } catch (error: unknown) {
      let message = "Failed to delete rule: ";
      if (error instanceof ApiError) {
        message += error.body.detail;
      } else {
        message += error;
      }
      toast.error(message);
    } finally {
      setIsDeleting(false);
      resetRule();
    }
  };

  const handleTenantChange = (tenant: string | null) => {
    setSelectedTenant(tenant);
    resetRule();
    mutate(rules_cache_key);
  };

  useEffect(() => {
    if (isLoadingRules || !tenantRules || !selectedRuleId) return;
    if (isRuleManuallyEdited) return;

    const rule = tenantRules.find((rule) => rule.identifier === selectedRuleId);
    if (!rule) return;

    const newRuleLogic =
      rule.timeseries_ids?.map((ts_id, index) => {
        const thresholdValue = rule.thresholds?.[index];
        const operator = index === 0 ? "" : rule.logical_operators?.[index - 1];

        return {
          timeSeries: ts_id || "",
          comparisonOperator: rule.comparison_operators?.[
            index
          ] as ComparisonOperator,
          threshold:
            typeof thresholdValue === "string"
              ? parseFloat(thresholdValue) || 0
              : (thresholdValue ?? 0),
          operator: operator as LogicalOperator,
        };
      }) || [];

    const isEqual = JSON.stringify(newRuleLogic) === JSON.stringify(ruleLogic);

    if (!isEqual) {
      ruleLogicHandler.setState(newRuleLogic);
      setIsRuleManuallyEdited(true);
    }
  }, [
    tenantRules,
    selectedRuleId,
    isLoadingRules,
    ruleLogic,
    ruleLogicHandler,
    isRuleManuallyEdited,
  ]);

  const handleSelectRuleId = (ruleId: string) => {
    setIsRuleManuallyEdited(false);
    setSelectedRuleId(ruleId);
  };

  useEffect(() => {
    if (selectedTenant) {
      setTenantRuleIds(
        tenantRules?.map((rule) => rule.identifier).sort() || [],
      );
    }
  }, [selectedTenant, tenantRules]);

  if (error) return <p>Error: {error.message}</p>;
  if (isLoadingTenants || !data) return <Loader mt="lg" />;

  const labels = data.map((tenant) => tenant.identifier || "");
  const allTenantRullPills = tenantRuleIds?.map((rullId, index) => {
    return (
      <Pill
        key={index}
        withRemoveButton
        onRemove={() => deleteRule(rullId)}
        onClick={() => handleSelectRuleId(rullId)}
        className={style.pill}
      >
        {rullId}
      </Pill>
    );
  });

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <Stack gap="md">
          <Select
            label="Tenant"
            placeholder="Select tenant"
            data={labels}
            value={selectedTenant}
            onChange={handleTenantChange}
          />
          <Stack gap="md">
            <Text fw={500} size="sm">
              Current Rules
            </Text>
            <Group>
              {isLoadingRules || isDeleting ? (
                <Loader />
              ) : (
                <>
                  <Pill.Group>{allTenantRullPills}</Pill.Group>
                  {!tenantRuleIds.length && (
                    <Text size="sm" c="dimmed">
                      {selectedTenant
                        ? "No rules defined for this tenant"
                        : "Select a tenant to view states"}
                    </Text>
                  )}
                </>
              )}
            </Group>
            <SelectCreatable
              label="Rule Identifier"
              placeholder="Select or create state identifier"
              initialData={tenantRuleIds || []}
              value={selectedRuleId}
              setValue={handleSelectRuleId}
            />
            <HandleRuleLogic
              ruleLogic={ruleLogic}
              ruleLogicHandler={ruleLogicHandler}
              timeseries={timeseries}
            />
            <Group>
              <Button
                mt="md"
                disabled={
                  !selectedTenant || !selectedRuleId || !ruleLogic.length
                }
                onClick={updateRule}
                loading={isSaving}
              >
                Save
              </Button>
            </Group>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default UpdateRuleProcessor;

const fetchAlltenants = (apiClient: BackendClient) =>
  apiClient.tenant.getListTenantsGet();

const fetchTenantRules = (apiClient: BackendClient, tenant: string | null) => {
  if (!tenant) return;
  return apiClient.rule.getListTenantsTenantIdRulesGet(tenant);
};

const fetchTimeSeries = (apiClient: BackendClient, tenant: string | null) => {
  if (!tenant) return;
  return apiClient.entities.getEntitiesTenantsTenantIdEntitiesGet(tenant, [
    EntityEnum.TIME_SERIES,
  ]);
};
