/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from "./aiomatic_warehouse__models__entity__tenant__Tenant__Reference";

export type aiomatic_warehouse__models__entity__user__User__Reference = {
  basetype?: aiomatic_warehouse__models__entity__user__User__Reference.basetype;
  id: string;
  tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
};

export namespace aiomatic_warehouse__models__entity__user__User__Reference {
  export enum basetype {
    USER = "User",
  }
}
