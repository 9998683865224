import { Anchor, Flex, Paper, Text, Title } from "@mantine/core";
import useEnvironmentStore from "../../../hooks/useEnvironmentStore";
import useExcelStore from "../../../hooks/useExcelStore";

const UpdateStepperCompleted = () => {
  const { path, name } = useExcelStore();
  const { dashboardUrl } = useEnvironmentStore();

  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>Update Completed</Title>
        {path && (
          <>
            <Text>
              You are up-to-date with{" "}
              <Anchor href={path} target="_blank">
                <b>{name}</b>
              </Anchor>{" "}
              on{" "}
              <Anchor href={dashboardUrl} target="_blank">
                <b>{dashboardUrl}</b>
              </Anchor>
            </Text>
          </>
        )}
      </Flex>
    </Paper>
  );
};

export default UpdateStepperCompleted;
