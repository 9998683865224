/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SCWithDescendants } from "../models/SCWithDescendants";
import type { SCWithStateAssignements } from "../models/SCWithStateAssignements";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class StructuralComponentService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @deprecated
   * Get Descendants
   * Get a Structural Component and all its descendants.
   * @param tenantId
   * @param componentId
   * @param xTenantId
   * @returns SCWithDescendants Successful Response
   * @throws ApiError
   */
  public getDescendantsTenantsTenantIdStructuralcomponentsComponentIdDescendantsGet(
    tenantId: string,
    componentId: string,
    xTenantId?: string,
  ): CancelablePromise<SCWithDescendants> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}/structuralcomponents/{component_id}/descendants",
      path: {
        tenant_id: tenantId,
        component_id: componentId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get With State Assignments
   * Get all Structural Components with their state assignments.
   * @param tenantId
   * @param xTenantId
   * @returns SCWithStateAssignements Successful Response
   * @throws ApiError
   */
  public getWithStateAssignmentsTenantsTenantIdStructuralcomponentsGet(
    tenantId: string,
    xTenantId?: string,
  ): CancelablePromise<Array<SCWithStateAssignements>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}/structuralcomponents",
      path: {
        tenant_id: tenantId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
