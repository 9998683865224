import { create } from "zustand";
import { EnumEnvironment, EnvironmentState } from "../types/environment.type";

const environment = process.env.REACT_APP_ENVIRONMENT as EnumEnvironment;
const DashboardUrl = process.env.REACT_APP_DASHBOARD_URL;

if (!environment || !Object.values(EnumEnvironment).includes(environment)) {
  throw new Error(
    `REACT_APP_ENVIRONMENT "${environment}" is not set to a valid value between "staging", "production", "demo", or "sandbox"`,
  );
}

if (!DashboardUrl) {
  throw new Error("REACT_APP_DASHBOARD_URL is not set");
}

const determineColor = (env: string): string => {
  switch (env) {
    case EnumEnvironment.Staging:
      return "orange";
    case EnumEnvironment.Production:
      return "red";
    case EnumEnvironment.Demo:
      return "green";
    default:
      return "black";
  }
};

const useEnvironmentStore = create<EnvironmentState>((set, get) => ({
  environment: environment,
  color: determineColor(environment),
  dashboardUrl: DashboardUrl,
  activeStep: 0,
  maxStep: 0,
  maxActiveSandboxStep: 0,
  setActiveStep: (step) => {
    set({ activeStep: step });
  },
  setMaxActiveStep: (isValid: boolean) => {
    const step = isValid ? get().activeStep + 1 : get().activeStep;
    set({ maxStep: step });
  },
}));

export default useEnvironmentStore;
