import { Paper, Text, Title } from "@mantine/core";
import Summary from "../../components/Views/Summary";

const SummarydPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Model Summary
      </Title>
      <Text>Current models in the system</Text>
      <Summary />
    </Paper>
  );
};

export default SummarydPage;
