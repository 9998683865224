/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConditionerParamsDense = {
  n_networks?: number;
  n_layers?: number;
  width_factor?: number;
  activation?: ConditionerParamsDense.activation;
};

export namespace ConditionerParamsDense {
  export enum activation {
    SWISH = "swish",
  }
}
