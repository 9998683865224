/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from "./core/BaseHttpRequest";
import type { OpenAPIConfig } from "./core/OpenAPI";
import { FetchHttpRequest } from "./core/FetchHttpRequest";

import { ChartAssignmentService } from "./services/ChartAssignmentService";
import { ClassifierService } from "./services/ClassifierService";
import { DashboardService } from "./services/DashboardService";
import { DataSourceService } from "./services/DataSourceService";
import { EdgeDeviceService } from "./services/EdgeDeviceService";
import { EntitiesService } from "./services/EntitiesService";
import { ProcessorService } from "./services/ProcessorService";
import { RuleService } from "./services/RuleService";
import { StateService } from "./services/StateService";
import { StructuralComponentService } from "./services/StructuralComponentService";
import { TenantService } from "./services/TenantService";
import { UserService } from "./services/UserService";
import { WidgetService } from "./services/WidgetService";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class BackendClient {
  public readonly chartAssignment: ChartAssignmentService;
  public readonly classifier: ClassifierService;
  public readonly dashboard: DashboardService;
  public readonly dataSource: DataSourceService;
  public readonly edgeDevice: EdgeDeviceService;
  public readonly entities: EntitiesService;
  public readonly processor: ProcessorService;
  public readonly rule: RuleService;
  public readonly state: StateService;
  public readonly structuralComponent: StructuralComponentService;
  public readonly tenant: TenantService;
  public readonly user: UserService;
  public readonly widget: WidgetService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = FetchHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? "",
      VERSION: config?.VERSION ?? "0.0.0",
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? "include",
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.chartAssignment = new ChartAssignmentService(this.request);
    this.classifier = new ClassifierService(this.request);
    this.dashboard = new DashboardService(this.request);
    this.dataSource = new DataSourceService(this.request);
    this.edgeDevice = new EdgeDeviceService(this.request);
    this.entities = new EntitiesService(this.request);
    this.processor = new ProcessorService(this.request);
    this.rule = new RuleService(this.request);
    this.state = new StateService(this.request);
    this.structuralComponent = new StructuralComponentService(this.request);
    this.tenant = new TenantService(this.request);
    this.user = new UserService(this.request);
    this.widget = new WidgetService(this.request);
  }
}
