import { Table } from "@mantine/core";
import React from "react";
import ReactDiffViewer from "react-diff-viewer-continued";
import { EntityUpdate } from "../../../generated";

interface DataSourceUpdatesProps {
  updates: EntityUpdate[];
  hideIdentifier?: boolean;
}

const EntityUpdatesTable: React.FC<DataSourceUpdatesProps> = ({
  updates,
  hideIdentifier,
}) => {
  const renderDifferenceDetails = (oldVal: any, newVal: any) => {
    const oldValue = JSON.stringify(oldVal, null, 2);
    const newValue = JSON.stringify(newVal, null, 2);

    return (
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={newValue}
        splitView={true}
        showDiffOnly={false}
        useDarkTheme={false}
        hideLineNumbers={true}
      />
    );
  };

  const rows = updates.map((update) => {
    return update.differences.map((difference, index) => {
      return (
        <Table.Tr key={index}>
          {!hideIdentifier && <Table.Td>{update.identifier}</Table.Td>}
          <Table.Td>{difference.field}</Table.Td>
          <Table.Td>
            {renderDifferenceDetails(
              difference.old_value,
              difference.new_value,
            )}
          </Table.Td>
        </Table.Tr>
      );
    });
  });

  return (
    <Table stickyHeader striped>
      <Table.Thead>
        <Table.Tr>
          {!hideIdentifier && <Table.Th>Identifier</Table.Th>}
          <Table.Th>Field</Table.Th>
          <Table.Th>Differences</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
};

export default EntityUpdatesTable;
