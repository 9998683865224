import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { backendLoginRequest, graphLoginRequest } from "../config/authConfig";

export enum AuthScope {
  GraphAPI = "GraphAPI",
  BackendAPI = "BackendAPI",
}

function getLoginRequest(scope: AuthScope) {
  switch (scope) {
    case AuthScope.GraphAPI:
      return graphLoginRequest;
    case AuthScope.BackendAPI:
      return backendLoginRequest;
    default:
      throw new Error(`Unsupported scope: ${scope}`);
  }
}

export const useAuth = (scope: AuthScope) => {
  const { instance, accounts } = useMsal();
  const loginRequest = getLoginRequest(scope);

  const acquireToken = useCallback(() => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    return instance
      .acquireTokenSilent(request)
      .then((response) => response.accessToken)
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.acquireTokenPopup(request);
            return response.accessToken;
          } catch (popupError) {
            console.error("Failed to acquire token via popup", popupError);
            throw popupError;
          }
        } else {
          console.error("Failed to acquire token silently", error);
          throw error;
        }
      });
  }, [instance, accounts, loginRequest]);

  return { acquireToken, accounts };
};
