import { getJsDateFromExcel } from "excel-date-to-js";
import {
  DatasourceData,
  DescriptionData,
  EdgeData,
  ExclusionData,
  HierarchyData,
  ModelData,
  PartData,
} from "../../types/excel.type";

const trimString = (value: any) => {
  if (typeof value === "string") return value.trim();
  if (typeof value === "number") return value.toString().trim();
  return "";
};

const convertExcelToUTC = (excelDatetime: string) => {
  if (!excelDatetime) return "";
  const localDate = getJsDateFromExcel(excelDatetime);
  localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());
  return localDate.toISOString();
};

const checkIfValidExcelDatetime = (excelDatetime: string) => {
  if (!excelDatetime) return true;
  try {
    getJsDateFromExcel(excelDatetime);
    return true;
  } catch (error) {
    return false;
  }
};

const checkIfValidIdentifier = (identifier: string) => {
  const re = /^[a-zA-Z0-9_]+$/;
  return re.test(identifier);
};

const checkLenghtLimit = (value: string, limit = 124) => {
  return value.length <= limit;
};

const checkIfDuplicate = (array: string[]) => {
  return new Set(array).size !== array.length;
};

const findDuplicates = (array: string[]) => {
  return array.filter((item, index) => array.indexOf(item) !== index);
};

const checkIdentifiers = (
  identifiers: string[],
  sheetName: string,
  allowedDuplicate = false,
) => {
  // Validation: check if there are duplicate identifiers
  if (!allowedDuplicate && checkIfDuplicate(identifiers)) {
    return {
      identifiers: [],
      errorMessage:
        `${sheetName} sheet has duplicate identifiers: ` +
        findDuplicates(identifiers).join(", "),
    };
  }

  // Validation: check if identifiers are valid
  if (!identifiers.every((identifier) => checkIfValidIdentifier(identifier))) {
    return {
      identifiers: [],
      errorMessage: `${sheetName} sheet has invalid identifiers. Only alphanumeric characters and underscores are allowed.`,
    };
  }

  // Validation: check if identifiers are within length limit
  if (!identifiers.every((identifier) => checkLenghtLimit(identifier))) {
    return {
      identifiers: [],
      errorMessage: `${sheetName} sheet has identifiers that are longer than 124 characters.`,
    };
  }

  return { identifiers, errorMessage: "" };
};

const convertDescriptionSheet = (
  data: string[][],
): { descriptionData: DescriptionData; descriptionErrorMessage: string } => {
  const descriptionData: DescriptionData = {
    tenant_identifier: "",
    tenant_label: "",
    dashboard_label: "",
    tenant_resolution: "",
  };

  if (data.length < 2) {
    return {
      descriptionData,
      descriptionErrorMessage:
        "Description sheet is empty or does not have enough data.",
    };
  }

  // Make sure the sheet has all the required parameters
  const expectedParameters = [
    "tenant_identifier",
    "tenant_label",
    "dashboard_label",
    "tenant_resolution",
  ];
  const missingParameters = expectedParameters.filter(
    (parameter) => !data.some((row) => row[0] === parameter),
  );
  if (missingParameters.length > 0) {
    return {
      descriptionData,
      descriptionErrorMessage:
        missingParameters.length === 1
          ? "Description sheet does not have parameter: " + missingParameters[0]
          : "Description sheet does not have parameters: " +
            missingParameters.join(", "),
    };
  }

  // Map the rest of the data to DescriptionData object
  data.forEach(([parameter, value]) => {
    // Validation: check if the parameter is in the descriptionData object
    if (!(parameter in descriptionData)) {
      return {
        descriptionData,
        descriptionErrorMessage: `Description sheet does not have parameter ${parameter}`,
      };
    }

    if (parameter && value !== undefined && parameter in descriptionData) {
      descriptionData[parameter as keyof DescriptionData] = value;
    }
  });
  return { descriptionData, descriptionErrorMessage: "" };
};

const convertEdgeSheet = (
  data: string[][],
): { edgeData: EdgeData[]; edgeErrorMessage: string } => {
  // Check if the data has at least 2 rows (headers + 1 data row)
  if (data.length < 2) {
    return {
      edgeData: [],
      edgeErrorMessage: "Edge sheet is empty or does not have enough data.",
    };
  }

  // Extract headers and validate them
  const headers = data[0];
  const expectedHeaders = [
    "Identifier",
    "Heartbeat Interval",
    "Active Endpoint",
    "Active Edgedevice",
  ];

  // Validation: check headers
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return {
      edgeData: [],
      edgeErrorMessage: "Edge sheet does not have the expected headers.",
    };
  }

  // Create a mapping for column index based on the header for easy access
  const headerIndexMapping = headers.reduce(
    (acc, header, index) => {
      acc[header] = index;
      return acc;
    },
    {} as { [key: string]: number },
  );

  // Validation: check identifiers
  const identifiers = data
    .slice(1)
    .map((row) => trimString(row[0]))
    .filter((row) => row);
  const { errorMessage } = checkIdentifiers(identifiers, "Edge");

  // Map the rest of the data to EdgeData objects
  return {
    edgeData: data
      .slice(1)
      .map((row) => {
        return {
          Identifier: trimString(row[headerIndexMapping["Identifier"]]),
          "Heartbeat Interval":
            parseInt(row[headerIndexMapping["Heartbeat Interval"]]) || 0,
          "Active Endpoint": trimString(
            row[headerIndexMapping["Active Endpoint"]],
          ),
          "Active Edgedevice": trimString(
            row[headerIndexMapping["Active Edgedevice"]],
          ),
        };
      })
      .filter(
        (edge) => edge.Identifier !== "" && edge["Heartbeat Interval"] > 0,
      ),
    edgeErrorMessage: errorMessage,
  };
};

const convertDatasourceSheet = (
  data: string[][],
): { datasourceData: DatasourceData[]; datasourceErrorMessage: string } => {
  // Check if the data has at least 2 rows (headers + 1 data row)
  if (data.length < 2) {
    return {
      datasourceData: [],
      datasourceErrorMessage:
        "Datasource sheet is empty or does not have enough data.",
    };
  }

  // Extract headers and validate them
  const headers = data[0];
  const expectedHeaders = [
    "Identifier",
    "Label",
    "Type",
    "OPC UA Name",
    "OPC UA Node ID",
    "Edge Endpoint",
    "DataType",
    "Datasource Description",
    "Typical Value",
    "Unit",
    "Scale",
    "Offset",
    "Linked Datasource",
  ];

  // Validation: check headers
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    const missingHeaders = expectedHeaders.filter(
      (header) => !headers.includes(header),
    );
    return {
      datasourceData: [],
      datasourceErrorMessage:
        missingHeaders.length === 1
          ? "Datasource sheet does not have the header: " + missingHeaders[0]
          : "Datasource sheet does not have the headers: " +
            missingHeaders.join(", "),
    };
  }

  // Validation: check identifiers
  const identifiers = data
    .slice(1)
    .map((row) => trimString(row[0]))
    .filter((row) => row);
  const { errorMessage } = checkIdentifiers(identifiers, "Datasource");

  // Create a mapping for column index based on the header for easy access
  const headerIndexMapping = headers.reduce(
    (acc, header, index) => {
      acc[header] = index;
      return acc;
    },
    {} as { [key: string]: number },
  );

  // Map the rest of the data to DatasourceData objects
  return {
    datasourceData: data
      .slice(1)
      .map((row) => {
        return {
          Identifier: trimString(row[headerIndexMapping["Identifier"]]),
          Label: trimString(row[headerIndexMapping["Label"]]),
          Type: trimString(row[headerIndexMapping["Type"]]),
          "OPC UA Name": trimString(row[headerIndexMapping["OPC UA Name"]]),
          "OPC UA Node ID": trimString(
            row[headerIndexMapping["OPC UA Node ID"]],
          ),
          "Edge Endpoint": trimString(row[headerIndexMapping["Edge Endpoint"]]),
          DataType: trimString(row[headerIndexMapping["DataType"]]),
          "Datasource Description": trimString(
            row[headerIndexMapping["Datasource Description"]],
          ),
          "Typical Value": trimString(row[headerIndexMapping["Typical Value"]]),
          Unit: trimString(row[headerIndexMapping["Unit"]]),
          Scale: parseFloat(row[headerIndexMapping["Scale"]]) || 1,
          Offset: parseFloat(row[headerIndexMapping["Offset"]]) || 0,
          "Linked Datasource": trimString(
            row[headerIndexMapping["Linked Datasource"]],
          ),
        };
      })
      .filter((parameter) => parameter.Identifier !== ""),
    datasourceErrorMessage: errorMessage,
  };
};

const convertModelSheet = (
  data: string[][],
): { modelData: ModelData[]; modelErrorMessage: string } => {
  // Check if the data has at least 2 rows (headers + 1 data row)
  if (data.length < 2) {
    return {
      modelData: [],
      modelErrorMessage: "Model sheet is empty or does not have enough data.",
    };
  }

  const headers = data[0];
  const expectedHeaders = [
    "Identifier",
    "Label",
    "Feature",
    "Condition 1",
    "Condition 2",
    "Condition 3",
    "Condition 4",
    "Condition 5",
    "Condition 6",
    "Condition 7",
    "Condition 8",
    "Condition 9",
    "Condition 10",
  ];

  // Validation: check headers
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return {
      modelData: [],
      modelErrorMessage: "Model sheet does not have the expected headers.",
    };
  }

  // Validation: check identifiers
  const identifiers = data
    .slice(1)
    .map((row) => trimString(row[0]))
    .filter((row) => row);
  const { errorMessage: errorMessageIdentifiers } = checkIdentifiers(
    identifiers,
    "Model",
  );

  // Validation: check if there are row-wise duplicates between feature and condition 1 to 10
  let errorMessageDuplicates = "";
  const featureAndConditions = data
    .slice(1)
    .map((row) => row.slice(2, 13))
    .filter((row) => row[0])
    .map((row) => row.filter((value) => value !== ""));
  for (let i = 0; i < featureAndConditions.length; i++) {
    if (checkIfDuplicate(featureAndConditions[i])) {
      const duplicates = findDuplicates(featureAndConditions[i]);
      errorMessageDuplicates =
        "Model sheet has duplicate features or conditions in row " +
        (i + 2) +
        ": " +
        duplicates.join(", ");
    }
  }

  // Create a mapping for column index based on the header for easy access
  const headerIndexMapping = headers.reduce(
    (acc, header, index) => {
      acc[header] = index;
      return acc;
    },
    {} as { [key: string]: number },
  );

  // Map the rest of the data to ModelData objects
  return {
    modelData: data
      .slice(1)
      .map((row) => {
        return {
          Identifier: trimString(row[headerIndexMapping["Identifier"]]),
          Label: trimString(row[headerIndexMapping["Label"]]),
          Feature: trimString(row[headerIndexMapping["Feature"]]),
          "Condition 1": trimString(row[headerIndexMapping["Condition 1"]]),
          "Condition 2": trimString(row[headerIndexMapping["Condition 2"]]),
          "Condition 3": trimString(row[headerIndexMapping["Condition 3"]]),
          "Condition 4": trimString(row[headerIndexMapping["Condition 4"]]),
          "Condition 5": trimString(row[headerIndexMapping["Condition 5"]]),
          "Condition 6": trimString(row[headerIndexMapping["Condition 6"]]),
          "Condition 7": trimString(row[headerIndexMapping["Condition 7"]]),
          "Condition 8": trimString(row[headerIndexMapping["Condition 8"]]),
          "Condition 9": trimString(row[headerIndexMapping["Condition 9"]]),
          "Condition 10": trimString(row[headerIndexMapping["Condition 10"]]),
        };
      })
      .filter((model) => model.Identifier !== ""),
    modelErrorMessage: errorMessageIdentifiers || errorMessageDuplicates,
  };
};

const convertPartSheet = (
  data: string[][],
): { partData: PartData[]; partErrorMessage: string } => {
  // Check if the data has at least 2 rows (headers + 1 data row)
  if (data.length < 2) {
    return {
      partData: [],
      partErrorMessage: "Part sheet is empty or does not have enough data.",
    };
  }

  const headers = data[0];
  const expectedHeaders = ["Identifier", "Label"];

  // Validation: check headers
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return {
      partData: [],
      partErrorMessage: "Part sheet does not have the expected headers.",
    };
  }

  // Validation: check identifiers
  const identifiers = data
    .slice(1)
    .map((row) => trimString(row[0]))
    .filter((row) => row);
  const { errorMessage } = checkIdentifiers(identifiers, "Part");

  // Create a mapping for column index based on the header for easy access
  const headerIndexMapping = headers.reduce(
    (acc, header, index) => {
      acc[header] = index;
      return acc;
    },
    {} as { [key: string]: number },
  );

  // Map the rest of the data to PartData objects
  return {
    partData: data
      .slice(1)
      .map((row) => {
        return {
          Identifier: trimString(row[headerIndexMapping["Identifier"]]),
          Label: trimString(row[headerIndexMapping["Label"]]),
        };
      })
      .filter((part) => part.Identifier !== ""),
    partErrorMessage: errorMessage,
  };
};

const convertHierarchySheet = (
  data: string[][],
): { hierarchyData: HierarchyData[]; hierarchyErrorMessage: string } => {
  // Check if the data has at least 2 rows (headers + 1 data row)
  if (data.length < 2) {
    return {
      hierarchyData: [],
      hierarchyErrorMessage:
        "Hierarchy sheet is empty or does not have enough data.",
    };
  }

  const headers = data[0];
  const expectedHeaders = [
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level 6",
    "Level 7",
    "Level 8",
    "Level 9",
    "Level 10",
  ];

  // Validation: check headers
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return {
      hierarchyData: [],
      hierarchyErrorMessage:
        "Hierarchy sheet does not have the expected headers.",
    };
  }

  // Validation: check that there is no duplicate in the hierarchy
  let errorMessageDuplicates = "";
  const hierarchy = data
    .slice(1)
    .map((row) => row.slice(0, 10))
    .flat();
  if (checkIfDuplicate(hierarchy)) {
    const duplicates = findDuplicates(hierarchy);
    errorMessageDuplicates =
      "Hierarchy sheet has duplicate identifiers: " + duplicates.join(", ");
  }

  // Create a mapping for column index based on the header for easy access
  const headerIndexMapping = headers.reduce(
    (acc, header, index) => {
      acc[header] = index;
      return acc;
    },
    {} as { [key: string]: number },
  );

  // Map the rest of the data to HierarchyData objects
  return {
    hierarchyData: data.slice(1).map((row) => {
      return {
        "Level 1": trimString(row[headerIndexMapping["Level 1"]]),
        "Level 2": trimString(row[headerIndexMapping["Level 2"]]),
        "Level 3": trimString(row[headerIndexMapping["Level 3"]]),
        "Level 4": trimString(row[headerIndexMapping["Level 4"]]),
        "Level 5": trimString(row[headerIndexMapping["Level 5"]]),
        "Level 6": trimString(row[headerIndexMapping["Level 6"]]),
        "Level 7": trimString(row[headerIndexMapping["Level 7"]]),
        "Level 8": trimString(row[headerIndexMapping["Level 8"]]),
        "Level 9": trimString(row[headerIndexMapping["Level 9"]]),
        "Level 10": trimString(row[headerIndexMapping["Level 10"]]),
      };
    }),
    hierarchyErrorMessage: errorMessageDuplicates,
  };
};

const convertExclusionSheet = (
  data: string[][],
): { exclusionData: ExclusionData[]; exclusionErrorMessage: string } => {
  // Check if the data has at least 2 rows (headers + 1 data row)
  if (data.length < 2) {
    return {
      exclusionData: [],
      exclusionErrorMessage: "",
    };
  }

  const headers = data[0];
  const expectedHeaders = ["Identifier", "Start", "End"];

  // Validation: check headers
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return {
      exclusionData: [],
      exclusionErrorMessage:
        "Exclusion sheet does not have the expected headers.",
    };
  }

  // Validation: check identifiers
  const identifiers = data
    .slice(1)
    .map((row) => trimString(row[0]))
    .filter((row) => row);
  const { errorMessage: errorMessageIdentifiers } = checkIdentifiers(
    identifiers,
    "Exclusion",
    true,
  );

  // Create a mapping for column index based on the header for easy access
  const headerIndexMapping = headers.reduce(
    (acc, header, index) => {
      acc[header] = index;
      return acc;
    },
    {} as { [key: string]: number },
  );

  // Check if the start and end time are valid format
  const hasInvalidDate = data.slice(1).some((row) => {
    return (
      !checkIfValidExcelDatetime(row[headerIndexMapping["Start"]]) ||
      !checkIfValidExcelDatetime(row[headerIndexMapping["End"]])
    );
  });

  if (hasInvalidDate) {
    return {
      exclusionData: [],
      exclusionErrorMessage:
        "Exclusion sheet has invalid start or end time. Please use Excel datetime format.",
    };
  }

  // Map the rest of the data to ExclusionData objects
  return {
    exclusionData: data
      .slice(1)
      .map((row) => {
        return {
          Identifier: trimString(row[headerIndexMapping["Identifier"]]),
          Start: convertExcelToUTC(row[headerIndexMapping["Start"]]),
          End: convertExcelToUTC(row[headerIndexMapping["End"]]),
        };
      })
      .filter((exclusion) => exclusion.Identifier !== ""),
    exclusionErrorMessage: errorMessageIdentifiers,
  };
};

export {
  convertDatasourceSheet,
  convertDescriptionSheet,
  convertEdgeSheet,
  convertExclusionSheet,
  convertHierarchySheet,
  convertModelSheet,
  convertPartSheet,
};
