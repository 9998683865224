import { Table } from "@mantine/core";
import { User } from "../../../generated";

interface UsersTableProps {
  users: User[];
}

const UsersTable = ({ users }: UsersTableProps) => {
  return (
    <Table stickyHeader striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Identifier</Table.Th>
          <Table.Th>Email</Table.Th>
          <Table.Th>Username</Table.Th>
          <Table.Th>Roles</Table.Th>
          <Table.Th>Created At</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {users.map((user) => (
          <Table.Tr key={user.id}>
            <Table.Td>{user.identifier}</Table.Td>
            <Table.Td>{user.email}</Table.Td>
            <Table.Td>{user.username}</Table.Td>
            <Table.Td>{user.roles.join(", ")}</Table.Td>
            <Table.Td>{user.created_at}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default UsersTable;
