/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumeration of different entity types.
 */
export enum EntityEnum {
  DATA_SOURCE = "DataSource",
  TIME_SERIES = "TimeSeries",
  TSPROCESSOR = "TSProcessor",
  STRUCTURAL_COMPONENT = "StructuralComponent",
  STATE_TYPE = "StateType",
  USER = "User",
}
