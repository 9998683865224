import { Paper, Text, Title } from "@mantine/core";
import UpdateStateAssignement from "../../components/Utility/UpdateStateAssignement";

const StateAssignmentPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Manage Assignements
      </Title>
      <Text mb="xl">Update state assignements for each tenant</Text>
      <UpdateStateAssignement />
    </Paper>
  );
};

export default StateAssignmentPage;
