import { ActionIcon, Box, Button, List, Text } from "@mantine/core";
import {
  DatePickerInput,
  DatePickerProps,
  DatesRangeValue,
  TimeInput,
} from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";

interface DateRange {
  start: Date;
  end: Date;
}

interface MultiRangeDatePickerInputProps {
  value: DateRange[];
  onChange: (ranges: DateRange[]) => void;
}

// Define the options to exclude seconds in UI display
const dateTimeOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
};

const MultiRangeDatePicker: React.FC<MultiRangeDatePickerInputProps> = ({
  value,
  onChange,
}) => {
  const [currentRange, setCurrentRange] = useState<DatesRangeValue>([
    null,
    null,
  ]);

  const refL = useRef<HTMLInputElement>(null);
  const refR = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // When Timepicker is enabled set default time 00:00
    if (refL.current) {
      refL.current.value = "00:00";
    }
    if (refR.current) {
      refR.current.value = "23:59";
    }
  }, []);

  const [error, setError] = useState<string | null>(null);

  const handleDateChange = (value: DatesRangeValue) => {
    setError(null);
    setCurrentRange(value);
  };

  const rangesOverlap = (rangeOld: DateRange, rangeNew: DateRange) => {
    // Complete overlap
    if (rangeNew.start < rangeOld.start && rangeNew.end > rangeOld.end)
      return true;
    // Starts within existing range
    if (rangeNew.start >= rangeOld.start && rangeNew.start < rangeOld.end)
      return true;
    // Ends within existing range
    if (rangeNew.end > rangeOld.start && rangeNew.end <= rangeOld.end)
      return true;
  };

  const addRange = () => {
    if (currentRange[0] && currentRange[1]) {
      if (refL.current) {
        const startTime = refL.current?.value.split(":");
        currentRange[0].setHours(
          parseInt(startTime[0]),
          parseInt(startTime[1]),
        );
      }
      if (refR.current) {
        const endTime = refR.current?.value.split(":");
        currentRange[1].setHours(parseInt(endTime[0]), parseInt(endTime[1]));
      }

      const newRange = {
        start: currentRange[0] as Date,
        end: currentRange[1] as Date,
      };
      const overlap = value.some((existingRange) =>
        rangesOverlap(existingRange, newRange),
      );

      if (overlap) {
        setError("The selected time range overlaps with an existing range.");
        return;
      }
      const newRanges = [...value, newRange];
      onChange(newRanges);
      setCurrentRange([null, null]);
      if (refL.current) {
        refL.current.value = "00:00";
      }
      if (refR.current) {
        refR.current.value = "23:59";
      }
      setError(null);
    }
  };

  const removeRange = (index: number) => {
    const newRanges = value.filter((_, i) => i !== index);
    onChange(newRanges);
  };

  const getSelectedDays = (): Date[] => {
    // Use the ranges (array of DateRange objs) flatten them into one array and
    return value.flatMap((range) => {
      const days = [];
      for (
        let d = new Date(range.start);
        d <= range.end;
        d.setDate(d.getDate() + 1)
      ) {
        days.push(new Date(d));
      }
      return days;
    });
  };

  const dayRenderer: DatePickerProps["renderDay"] = (date) => {
    // Extract the day of the month from date obj
    const day = date.getDate();
    const isHighlighted = getSelectedDays().some(
      (selectedDate) => selectedDate.toDateString() === date.toDateString(),
    );
    return (
      <div
        style={{
          backgroundColor: isHighlighted ? "#3D920C" : undefined,
          color: isHighlighted ? "white" : undefined,
          borderRadius: "10%",
          width: "2em",
          height: "2em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {day}
      </div>
    );
  };

  return (
    <Box>
      <DatePickerInput
        label="Select (multiple) date ranges"
        value={currentRange}
        onChange={handleDateChange}
        valueFormat="DD/MM/YYYY"
        placeholder="Pick range"
        type="range"
        renderDay={dayRenderer}
        mt="md"
        clearable
        allowSingleDateInRange
      />
      <TimeInput
        disabled={!(currentRange[0] && currentRange[1])}
        display={"inline-block"}
        mt={"sm"}
        mr={"sm"}
        maw={"115"}
        label="Start Time"
        ref={refL}
      />
      <TimeInput
        disabled={!(currentRange[0] && currentRange[1])}
        display={"inline-block"}
        mt={"sm"}
        maw={"115"}
        label="End Time"
        ref={refR}
      />

      <Button
        display={"block"}
        onClick={addRange}
        disabled={!currentRange[0] || !currentRange[1]}
        mt="sm"
      >
        <Text fz={"sm"}>Add Range</Text>
      </Button>
      {error && (
        <Text size="sm" c="red">
          {error}
        </Text>
      )}
      {value.length > 0 && (
        <>
          <Text fz={"sm"} mt={"xs"}>
            Selected Ranges:
          </Text>
          <List
            mt="md"
            spacing="xs"
            size="xs"
            listStyleType={"none"}
            center={true}
          >
            {value.map((range, index) => (
              <List.Item key={index}>
                <ActionIcon
                  display={"inline-block"}
                  size={"sm"}
                  color="red"
                  mr={"sm"}
                  onClick={() => removeRange(index)}
                >
                  <IconX size={12} />
                </ActionIcon>
                <Text span size="sm">
                  {range.start.toLocaleString(undefined, dateTimeOptions)} -{" "}
                  {range.end.toLocaleString(undefined, dateTimeOptions)}
                </Text>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default MultiRangeDatePicker;
