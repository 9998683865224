import {
  Button,
  ColorPicker,
  Group,
  Loader,
  Paper,
  Pill,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { ApiError, BackendClient } from "../../../generated";
import { SelectCreatable } from "../../Controls/SelectCreatable";
import style from "./UpdateStateTenant.module.css";

const UpdateStateTenant = () => {
  const apiClient = useApiClient();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [selectedTenant, setSelectedTenant] = useState<string | null>("");
  const [tenantStateIds, setTenantStateIds] = useState<string[]>([]);
  const [selectedStateId, setSelectedStateId] = useState<string | null>(null);
  const [stateLabel, setStateLabel] = useState("");
  const [stateColor, setStateColor] = useState("");

  const {
    data,
    error,
    isLoading: isLoadingTenants,
  } = useSWR(["fetchAlltenants"], () => fetchAlltenants(apiClient));

  const state_cache_key = ["fetchTenantStates", selectedTenant];
  const { data: tenantStates, isLoading: isLoadingStates } = useSWR(
    state_cache_key,
    () => fetchTenantStates(apiClient, selectedTenant),
  );

  const state_details_cache_key = [
    "fetchStateDetails",
    selectedTenant,
    selectedStateId,
  ];
  const shouldFetchStateDetails = tenantStateIds.includes(
    selectedStateId || "",
  );
  const { data: stateDetailsData } = useSWR(
    shouldFetchStateDetails ? state_details_cache_key : null,
    () => fetchStateDetails(apiClient, selectedTenant, selectedStateId),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
    },
  );

  const resetState = () => {
    setSelectedStateId("");
    setStateColor("");
    setStateLabel("");
  };

  const updateState = async () => {
    try {
      if (!selectedTenant) throw new Error("Tenant is required");
      if (!selectedStateId) throw new Error("State identifier is required");
      if (!stateColor) throw new Error("State color is required");
      if (!stateLabel) throw new Error("State label is required");
      setIsSaving(true);
      await apiClient.state.upsertTenantsTenantIdStatesStateIdPut(
        selectedTenant,
        selectedStateId,
        stateColor,
        stateLabel,
      );
      toast.success("State updated successfully!");
    } catch (error: unknown) {
      let message = "Failed to update state: ";
      if (error instanceof ApiError) {
        message += error.body.detail;
      } else {
        message += error;
      }
      toast.error(message);
    } finally {
      mutate(state_cache_key);
      mutate(state_details_cache_key);
      setIsSaving(false);
    }
  };

  const deleteState = async (stateId: string) => {
    if (!window.confirm(`Are you sure you want to delete state ${stateId}?`))
      return;
    try {
      if (!selectedTenant) throw new Error("Tenant is required");
      if (!stateId) throw new Error("State identifier is required");
      setIsDeleting(true);
      await apiClient.state.deleteTenantsTenantIdStatesStateIdDelete(
        selectedTenant,
        stateId,
      );
      setTenantStateIds(tenantStateIds.filter((id) => id !== stateId));
      toast.success("State deleted successfully!");
      mutate(state_cache_key);
    } catch (error: unknown) {
      let message = "Failed to delete state: ";
      if (error instanceof ApiError) {
        message += error.body.detail;
      } else {
        message += error;
      }
      toast.error(message);
    } finally {
      setIsDeleting(false);
      resetState();
    }
  };

  const handleTenantChange = (tenant: string | null) => {
    setSelectedTenant(tenant);
    resetState();
    mutate(state_cache_key);
  };

  useEffect(() => {
    if (isLoadingStates || !tenantStates) return;
    const state = tenantStates.find(
      (state) => state.identifier === selectedStateId,
    );
    if (!state) return;
    setStateColor(state.hexcolor || "");
    setStateLabel(state.label || "");
  }, [tenantStates, selectedStateId, isLoadingStates]);

  useEffect(() => {
    if (!selectedTenant || !selectedStateId) return;
    mutate(state_details_cache_key);
    // eslint-disable-next-line
  }, [selectedTenant, selectedStateId]);

  useEffect(() => {
    if (!stateDetailsData) return;
    setStateColor(stateDetailsData.state.hexcolor || "");
    setStateLabel(stateDetailsData.state.label || "");
  }, [stateDetailsData]);

  useEffect(() => {
    if (selectedTenant) {
      setTenantStateIds(
        tenantStates?.map((state) => state.identifier).sort() || [],
      );
    }
  }, [selectedTenant, tenantStates]);

  if (error) return <p>Error: {error.message}</p>;
  if (isLoadingTenants || !data) return <Loader mt="lg" />;

  const labels = data.map((tenant) => tenant.identifier || "");
  const allTenantStatePills = tenantStateIds?.map((stateId, index) => {
    return (
      <Pill
        key={index}
        withRemoveButton
        onRemove={() => deleteState(stateId)}
        onClick={() => {
          if (isSaving) return;
          setSelectedStateId(stateId);
        }}
        className={style.pill}
      >
        {stateId}
      </Pill>
    );
  });

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <Stack gap="md">
          <Select
            label="Tenant"
            placeholder="Select tenant"
            data={labels}
            value={selectedTenant}
            onChange={handleTenantChange}
          />
          <Stack gap="md">
            <Text fw={500} size="sm">
              Current States
            </Text>
            <Group>
              {isLoadingStates || isDeleting ? (
                <Loader />
              ) : (
                <>
                  <Pill.Group>{allTenantStatePills}</Pill.Group>
                  {!tenantStateIds.length && (
                    <Text size="sm" c="dimmed">
                      {selectedTenant
                        ? "No states defined for this tenant"
                        : "Select a tenant to view states"}
                    </Text>
                  )}
                </>
              )}
            </Group>
            <SelectCreatable
              label="State Identifier"
              placeholder="Select or create state identifier"
              initialData={tenantStateIds || []}
              value={selectedStateId}
              setValue={setSelectedStateId}
            />
            <TextInput
              label="State Label"
              placeholder="Label"
              value={stateLabel}
              onChange={(event) => setStateLabel(event.currentTarget.value)}
            />
            <TextInput
              label="State Color"
              placeholder="Hex state Color"
              value={stateColor}
              onChange={(event) => setStateColor(event.currentTarget.value)}
            />
            <ColorPicker
              title="Color"
              format="hex"
              value={stateColor}
              onChange={setStateColor}
              swatches={swatches}
            />
            <Group>
              <Button
                mt="md"
                disabled={!selectedTenant}
                onClick={updateState}
                loading={isSaving}
              >
                Save
              </Button>
            </Group>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default UpdateStateTenant;

const fetchAlltenants = (apiClient: BackendClient) =>
  apiClient.tenant.getListTenantsGet();

const fetchTenantStates = (apiClient: BackendClient, tenant: string | null) => {
  if (!tenant) return;
  return apiClient.state.getListTenantsTenantIdStatesGet(tenant);
};

const fetchStateDetails = (
  apiClient: BackendClient,
  tenant: string | null,
  stateId: string | null,
) => {
  if (!tenant || !stateId) return;
  return apiClient.state.getTenantsTenantIdStatesStateIdGet(tenant, stateId);
};

const swatches = [
  "#2e2e2e",
  "#868e96",
  "#fa5252",
  "#e64980",
  "#be4bdb",
  "#7950f2",
  "#4c6ef5",
  "#228be6",
  "#15aabf",
  "#12b886",
  "#40c057",
  "#82c91e",
  "#fab005",
  "#fd7e14",
];
