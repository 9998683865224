import { Paper, Text, Title } from "@mantine/core";
import UpdateWidgets from "../../components/Widgets/UpdateWidgets";

const WidgetPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Update Widgets
      </Title>
      <Text mb="xl">Update the widgets for each part of the dashboard</Text>
      <UpdateWidgets />
    </Paper>
  );
};

export default WidgetPage;
