/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum representing logical operators.
 */
export enum LogicalOperator {
  AND = "AND",
  OR = "OR",
}
