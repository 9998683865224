import { useMsal } from "@azure/msal-react";
import { Button } from "@mantine/core";
import { backendLoginRequest } from "../../../config/authConfig";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      await instance.loginPopup(backendLoginRequest);
    } catch (error) {
      console.error("Popup login failed. Falling back to redirect.", error);
      instance.loginRedirect(backendLoginRequest);
    }
  };

  return <Button onClick={handleLogin}>Sign In</Button>;
};

export default SignInButton;
