/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataSource } from "../models/DataSource";
import type { DatasourceData } from "../models/DatasourceData";
import type { GenericResponse } from "../models/GenericResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class DataSourceService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get List
   * Get the list of data sources for a tenant.
   * @param tenantId
   * @param xTenantId
   * @returns DataSource Successful Response
   * @throws ApiError
   */
  public getListTenantsTenantIdDatasourcesGet(
    tenantId: string,
    xTenantId?: string,
  ): CancelablePromise<Array<DataSource>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}/datasources",
      path: {
        tenant_id: tenantId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update List
   * Update the list of data sources for a tenant.
   * @param tenantId
   * @param requestBody
   * @param xTenantId
   * @returns GenericResponse Successful Response
   * @throws ApiError
   */
  public updateListTenantsTenantIdDatasourcesPut(
    tenantId: string,
    requestBody: Array<DatasourceData>,
    xTenantId?: string,
  ): CancelablePromise<GenericResponse> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/tenants/{tenant_id}/datasources",
      path: {
        tenant_id: tenantId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
