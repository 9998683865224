/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tenant } from "../models/Tenant";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class TenantService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get
   * Get information about a tenant.
   * @param tenantId
   * @param xTenantId
   * @returns Tenant Successful Response
   * @throws ApiError
   */
  public getTenantsTenantIdGet(
    tenantId: string,
    xTenantId?: string,
  ): CancelablePromise<Tenant> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}",
      path: {
        tenant_id: tenantId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create
   * Create a new tenant.
   * @param tenantId
   * @param tenantLabel
   * @param dashboardLabel
   * @param resolution
   * @param xTenantId
   * @returns Tenant Successful Response
   * @throws ApiError
   */
  public createTenantsTenantIdPost(
    tenantId: string,
    tenantLabel: string,
    dashboardLabel: string,
    resolution: string,
    xTenantId?: string,
  ): CancelablePromise<Tenant> {
    return this.httpRequest.request({
      method: "POST",
      url: "/tenants/{tenant_id}",
      path: {
        tenant_id: tenantId,
      },
      query: {
        tenant_label: tenantLabel,
        dashboard_label: dashboardLabel,
        resolution: resolution,
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get List
   * Get list of tenants.
   * @param xTenantId
   * @returns Tenant Successful Response
   * @throws ApiError
   */
  public getListTenantsGet(
    xTenantId?: string,
  ): CancelablePromise<Array<Tenant>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants",
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
