import { Flex, Loader, Paper, Text, Title } from "@mantine/core";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { BackendClient } from "../../../generated";
import useExcelStore from "../../../hooks/useExcelStore";
import EdgeEndpointsTable from "../EdgeEndpointsTable";

const fetchTenant = async (
  apiClient: BackendClient,
  identifier: string | undefined,
) => {
  if (!identifier) {
    console.error("Identifier is required");
    return;
  }
  try {
    const response = await apiClient.tenant.getTenantsTenantIdGet(identifier);
    return response;
  } catch (error) {
    throw error;
  }
};

const EdgeDeviceInfo = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const tenant_identifier = sheetsData.Description?.tenant_identifier;

  const { data, error, isLoading } = useSWR(
    tenant_identifier ? ["tenant", tenant_identifier] : null,
    () => fetchTenant(apiClient, tenant_identifier),
    {
      shouldRetryOnError: false,
    },
  );
  const { edge_device_endpoints } = data || {};

  if (!tenant_identifier) return null;

  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>Edge Endpoints in Database</Title>
        {isLoading && (
          <>
            <Text>Loading edge endpoints...</Text>
            <Loader />
          </>
        )}
        {!isLoading && error && (
          <Text>
            Edge endpoints not found for "{tenant_identifier}" in the database
          </Text>
        )}
        {!isLoading && edge_device_endpoints && (
          <EdgeEndpointsTable endpoints={edge_device_endpoints} />
        )}
      </Flex>
    </Paper>
  );
};

export default EdgeDeviceInfo;
