/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from "./aiomatic_warehouse__models__entity__tenant__Tenant__Reference";

export type StateType = {
  basetype?: StateType.basetype;
  id: string;
  identifier: string;
  tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
  hexcolor: string;
  anomaly_detection_active?: boolean;
  icon_path?: string;
  label?: string;
};

export namespace StateType {
  export enum basetype {
    STATE_TYPE = "StateType",
  }
}
