import { useMsal } from "@azure/msal-react";
import { AppShell, Burger, Group, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignOutButton from "../../Authentication/SignOutButton";
import EnvironmentToggleButton from "../../Buttons/EnvironmentToggleButton";
import DoubleNavbar from "../DoubleNavbar";

interface Props {
  children: React.ReactNode;
}

function AppLayout({ children }: Props) {
  const [opened, { toggle }] = useDisclosure();
  const { accounts } = useMsal();
  const username = accounts[0]?.username;

  return (
    <AppShell
      footer={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Footer>
        <Group h="100%" px="md" justify="space-between">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Text c="dimmed">{username}</Text>
          <EnvironmentToggleButton />
          <SignOutButton />
        </Group>
      </AppShell.Footer>

      <AppShell.Navbar>
        <DoubleNavbar />
      </AppShell.Navbar>

      <AppShell.Main>
        <>
          {children}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      </AppShell.Main>
    </AppShell>
  );
}

export default AppLayout;
