import { List, Paper, Text, Title } from "@mantine/core";
import PickerWrapped from "../../components/Controls/PickerWrapped";
import ExcelExclusionTable from "../../components/Tables/ExcelExclusionTable";
import ExclusionTable from "../../components/Tables/ExclusionTable";

const ExclusionPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Exclusion Times
      </Title>
      <Text mb="md">
        Update the time ranges for exclusions during training.
      </Text>
      <List>
        <List.Item>
          <b>Time Format:</b> The time format in the Excel file is automatically
          recognized.
        </List.Item>
        <List.Item>
          <b>Time Zone:</b> The database uses UTC time, but the Excel file can
          be in any time zone.
        </List.Item>
        <List.Item>
          <b>Overlapping Times:</b> Any overlapping exclusion times are
          automatically merged into a single exclusion period.
        </List.Item>
        <List.Item>
          <b>Identifier Matching:</b> The identifier in the Excel file is used
          to match all models within the corresponding part of the hierarchy.
        </List.Item>
      </List>

      <PickerWrapped title="SharePoint" />
      <ExcelExclusionTable />
      <ExclusionTable />
    </Paper>
  );
};

export default ExclusionPage;
