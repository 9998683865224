/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from "./aiomatic_warehouse__models__entity__tenant__Tenant__Reference";
import type { Roles } from "./Roles";

export type User = {
  basetype?: User.basetype;
  id: string;
  identifier: string;
  tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
  email: string;
  username: string;
  roles: Array<Roles>;
  password_hash?: string;
  created_at: string;
  updated_at?: string;
};

export namespace User {
  export enum basetype {
    USER = "User",
  }
}
