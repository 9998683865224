/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AggregationTypeEnum } from "./AggregationTypeEnum";

export type AggregationProcessor = {
  basetype?: AggregationProcessor.basetype;
  agg: AggregationTypeEnum;
};

export namespace AggregationProcessor {
  export enum basetype {
    AGG_PROC = "agg_proc",
  }
}
