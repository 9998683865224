import { Flex, Loader, Paper, Text, Title } from "@mantine/core";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { BackendClient } from "../../../generated";
import useExcelStore from "../../../hooks/useExcelStore";
import ParameterTable from "../../Tables/ParameterTable";

const fetchParameters = async (
  apiClient: BackendClient,
  tenant_identifier: string | undefined,
) => {
  if (!tenant_identifier) {
    console.error("Identifier is required");
    return;
  }
  try {
    return await apiClient.dataSource.getListTenantsTenantIdDatasourcesGet(
      tenant_identifier,
    );
  } catch (error) {
    throw error;
  }
};

const ParameterInfo = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const tenant_identifier = sheetsData.Description?.tenant_identifier;

  const { data, error, isLoading } = useSWR(
    tenant_identifier ? ["parameters", tenant_identifier] : null,
    () => fetchParameters(apiClient, tenant_identifier),
    {
      shouldRetryOnError: true,
    },
  );

  const parameters = data || [];

  if (!tenant_identifier) return null;

  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>Parameters in Database</Title>
        <Text>
          The following table shows the parameters in the database and the
          values in <span style={{ color: "red" }}>red</span> are the ones from
          the Excel file that are different.
        </Text>

        {isLoading && (
          <>
            <Text>Loading parameters...</Text>
            <Loader />
          </>
        )}
        {error && (
          <Text>
            Parameters not found for "{tenant_identifier}" in the database
          </Text>
        )}
        {!isLoading && parameters && <ParameterTable data={parameters} />}
      </Flex>
    </Paper>
  );
};

export default ParameterInfo;
