/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum representing comparison operators.
 */
export enum ComparisonOperator {
  LT = "LT",
  LE = "LE",
  EQ = "EQ",
  GE = "GE",
  GT = "GT",
}
