import { Button, CopyButton, Table, Tooltip } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { DataSource } from "../../../generated";
import useExcelStore from "../../../hooks/useExcelStore";

interface ParameterTableProps {
  data: DataSource[];
}

const ParameterTable = ({ data }: ParameterTableProps) => {
  const { sheetsData } = useExcelStore();

  const db_parameters =
    data?.map((p) => {
      return {
        ...p,
        identifier: p.identifier.replace(/_DS/g, ""),
      };
    }) || [];

  const excel_parameters =
    sheetsData.Parameter?.map((p) => {
      return {
        identifier: p.Identifier,
        label: p.Label,
        offset: p.Offset,
        scale: p.Scale,
        unit: p.Unit,
        node_tag: p["OPC UA Node ID"],
      };
    }) || [];

  const format = (db: string | number, excel: string | number) => {
    if (db !== excel) {
      return (
        <>
          <span>{db}</span>
          <br />
          <span style={{ display: "flex", alignItems: "center", color: "red" }}>
            <IconAlertTriangle size="1rem" style={{ marginRight: "0.25rem" }} />
            {excel}
          </span>
        </>
      );
    }
    return db;
  };

  const rows = db_parameters.map((db, index) => {
    const excel = excel_parameters.find((p) => p.identifier === db.identifier);
    if (!excel) return null;
    if (!db) return null;
    return (
      <Table.Tr key={index}>
        <Table.Td>{format(db.identifier, excel.identifier)}</Table.Td>
        <Table.Td>{format(db.label || "", excel.label)}</Table.Td>
        <Table.Td>{format(db.offset || 0, excel.offset)}</Table.Td>
        <Table.Td>{format(db.scale || 0, excel.scale)}</Table.Td>
        <Table.Td>{format(db.unit || "", excel.unit)}</Table.Td>
        <Table.Td>{format(db.node_tag, excel.node_tag)}</Table.Td>
      </Table.Tr>
    );
  });

  const copyAsColumn = (data: string[] | number[]) => {
    return data.map((d) => d + "\n").join("");
  };

  const tableHeader = () => (
    <Table.Tr>
      <Table.Th>
        <CopyButton
          value={copyAsColumn(db_parameters.map((p) => p.identifier || ""))}
        >
          {({ copied, copy }) => (
            <Tooltip label="Copy all Identifiers">
              <Button size="xs" variant="default" onClick={copy}>
                {copied ? "Copied" : "Identifier"}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
      </Table.Th>
      <Table.Th>
        <CopyButton
          value={copyAsColumn(db_parameters.map((p) => p.label || ""))}
        >
          {({ copied, copy }) => (
            <Tooltip label="Copy all Labels">
              <Button size="xs" variant="default" onClick={copy}>
                {copied ? "Copied" : "Label"}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
      </Table.Th>
      <Table.Th>
        <CopyButton
          value={copyAsColumn(db_parameters.map((p) => p.offset || 0))}
        >
          {({ copied, copy }) => (
            <Tooltip label="Copy all Offsets">
              <Button size="xs" variant="default" onClick={copy}>
                {copied ? "Copied" : "Offset"}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
      </Table.Th>
      <Table.Th>
        <CopyButton
          value={copyAsColumn(db_parameters.map((p) => p.scale || 0))}
        >
          {({ copied, copy }) => (
            <Tooltip label="Copy all Scales">
              <Button size="xs" variant="default" onClick={copy}>
                {copied ? "Copied" : "Scale"}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
      </Table.Th>
      <Table.Th>
        <CopyButton
          value={copyAsColumn(db_parameters.map((p) => p.unit || ""))}
        >
          {({ copied, copy }) => (
            <Tooltip label="Copy all Units">
              <Button size="xs" variant="default" onClick={copy}>
                {copied ? "Copied" : "Unit"}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
      </Table.Th>
      <Table.Th>
        <CopyButton
          value={copyAsColumn(db_parameters.map((p) => p.node_tag || ""))}
        >
          {({ copied, copy }) => (
            <Tooltip label="Copy all Node Tags">
              <Button size="xs" variant="default" onClick={copy}>
                {copied ? "Copied" : "Node Tag"}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
      </Table.Th>
    </Table.Tr>
  );
  return (
    <Table stickyHeader striped>
      <Table.Thead>{tableHeader()}</Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
};

export default ParameterTable;
