export enum EnumEnvironment {
  Staging = "staging",
  Production = "production",
  Demo = "demo",
  Sandbox = "sandbox",
}

export interface EnvironmentState {
  environment: EnumEnvironment;
  color: string;
  dashboardUrl: string;
  activeStep: number;
  maxStep: number;

  setActiveStep: (step: number) => void;
  setMaxActiveStep: (isValid: boolean) => void;
}
