import { Button, Flex, Group, Paper, Stepper, Title } from "@mantine/core";
import { useEffect } from "react";
import useEnvironmentStore from "../../../hooks/useEnvironmentStore";
import Picker from "../../Controls/Picker";
import UpdateDashboard from "../../Dashboard/UpdateDashboard";
import UpdateEdgeDevices from "../../EdgeManagement/UpdateEdgeDevices";
import CreateTenant from "../../Forms/CreateTenant";
import UpdateParameters from "../UpdateParameters";
import UpdateStepperCompleted from "../UpdateStepperCompleted";

function UpdateStepper() {
  const nextStep = () => {
    const currentStep = useEnvironmentStore.getState().activeStep;
    const maxStep = useEnvironmentStore.getState().maxStep;
    const nextStep = currentStep < maxStep ? currentStep + 1 : currentStep;
    useEnvironmentStore.getState().setActiveStep(nextStep);
  };

  const prevStep = () => {
    const currentStep = useEnvironmentStore.getState().activeStep;
    const prevStep = currentStep > 0 ? currentStep - 1 : currentStep;
    useEnvironmentStore.getState().setActiveStep(prevStep);
  };

  const active = useEnvironmentStore((state) => state.activeStep);
  const maxStep = useEnvironmentStore((state) => state.maxStep);
  const setActiveStep = useEnvironmentStore((state) => state.setActiveStep);

  const isNextStepDisabled = active >= maxStep || active === steps.length;

  useEffect(() => {
    if (active > maxStep) {
      setActiveStep(maxStep);
    }
  }, [active, maxStep, setActiveStep]);

  return (
    <>
      <Stepper
        active={active}
        onStepClick={setActiveStep}
        size="sm"
        allowNextStepsSelect={false}
      >
        {steps.map((step, index) => (
          <Stepper.Step key={index} label={step.label}>
            {active === index && (
              <Paper withBorder p={40} mt={40} radius="md">
                <Flex
                  mih={50}
                  gap="md"
                  justify="flex-start"
                  align="flex-start"
                  direction="column"
                >
                  <Title order={2}>{step.label}</Title>
                  {step.component}
                </Flex>
              </Paper>
            )}
          </Stepper.Step>
        ))}
        <Stepper.Completed>
          <UpdateStepperCompleted />
        </Stepper.Completed>
      </Stepper>

      <Group mt="xl">
        <Button variant="default" onClick={prevStep} disabled={active === 0}>
          Back
        </Button>
        <Button onClick={nextStep} disabled={isNextStepDisabled}>
          Next Step
        </Button>
      </Group>
    </>
  );
}

const steps = [
  {
    label: "Pick Excel File",
    component: <Picker />,
    title: "Pick Excel File",
  },
  {
    label: "Create Tenant",
    component: <CreateTenant />,
  },
  {
    label: "Update Edge Endpoints",
    component: <UpdateEdgeDevices />,
  },
  {
    label: "Update Parameters",
    component: <UpdateParameters />,
  },
  {
    label: "Update dashboard",
    component: <UpdateDashboard />,
  },
];

export default UpdateStepper;
