import "@mantine/core/styles.css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
import {
  MantineProvider,
  createTheme,
  MantineColorsTuple,
} from "@mantine/core";

const brightGreen: MantineColorsTuple = [
  "#effee7",
  "#e0f8d4",
  "#c2efab",
  "#a2e67e",
  "#87de57",
  "#75d940",
  "#6bd731",
  "#59be23",
  "#4da91b",
  "#3d920c",
];

const theme = createTheme({
  colors: {
    "bright-green": brightGreen,
  },
  primaryColor: "bright-green",
});

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
const AppWrapper = () => (
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MantineProvider theme={theme} defaultColorScheme="light">
        <App />
      </MantineProvider>
    </MsalProvider>
  </React.StrictMode>
);

root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
