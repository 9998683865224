import { Group, Loader, Paper, SimpleGrid, Text } from "@mantine/core";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import { BackendClient, SummaryResponse } from "../../../generated";
import classes from "./Summary.module.css";

const fetchSummary = (apiClient: BackendClient) =>
  apiClient.processor.getStatisticsStatisticsGet();

const keyToTitleMap: Record<keyof SummaryResponse, string> = {
  num_tenants: "Number of Tenants",
  num_ml_processors: "Number of Models",
  avg_conditions_per_ml_processor: "Avg Conditions per Model",
  max_conditions_per_ml_processor: "Max Conditions per Model",
};

const Summary = () => {
  const apiClient = useApiClient();
  const { data, error, isLoading } = useSWR(["summaryKey"], () =>
    fetchSummary(apiClient),
  );
  if (error) return <p>Error: {error.message}</p>;
  if (isLoading || !data) return <Loader mt="lg" />;

  const stats = Object.keys(data).map((key) => {
    const title = keyToTitleMap[key as keyof SummaryResponse];
    let value = data[key as keyof SummaryResponse];
    if (key === "avg_conditions_per_ml_processor") {
      value = parseFloat(value.toFixed(2));
    }
    return (
      <Paper withBorder p="md" radius="md" key={key}>
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            {title}
          </Text>
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>{value}</Text>
        </Group>
      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid cols={{ base: 1, xs: 2, md: 4 }}>{stats}</SimpleGrid>
    </div>
  );
};

export default Summary;
