import { Badge, Button, Loader, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import {
  ApiError,
  BackendClient,
  DashboardDifferences,
  StatusEnum,
} from "../../../generated";
import useEnvironmentStore from "../../../hooks/useEnvironmentStore";
import useExcelStore from "../../../hooks/useExcelStore";
import { SheetsData } from "../../../types/excel.type";
import ConfirmationButton from "../../Buttons/ConfirmationButton";
import ProgressBar from "../../Views/ProgressBar";
import DashboardDifferencesTable from "../DashboardDifferencesTable";

export const UpdateDashboard = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const { setMaxActiveStep } = useEnvironmentStore();
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [taskStatus, setTaskStatus] = useState<string>("");
  const [taskMessage, setTaskMessage] = useState<string>("");
  const [taskProgress, setTaskProgress] = useState<number>(0);
  const description = sheetsData.Description;
  const tenant_identifier = description?.tenant_identifier || "";

  const { statusResponse } = useTaskStatus(apiClient, tenant_identifier);
  const { data, isLoading, isValidating, fetchDifferences } =
    useDashboardDifferences(apiClient, tenant_identifier, sheetsData);

  const isUpToDate = checkIfUpToDate(data);
  const timeSeriesToDelete = data?.timeseries.remove || [];

  const handleUpdateDashboard = () => {
    updateDashboardDifferences(
      apiClient,
      sheetsData,
      timeSeriesToDelete.map((ts) => ts.identifier),
      setErrorMessage,
      setIsUpdating,
    );
  };

  useEffect(() => {
    setMaxActiveStep(isUpToDate);
  }, [isUpToDate, setMaxActiveStep]);

  useEffect(() => {
    if (!statusResponse) return;
    setTaskStatus(statusResponse.status);
    setTaskMessage(statusResponse.message || "");
    setTaskProgress(statusResponse.progress || 0);
    if (statusResponse?.status === StatusEnum.COMPLETED) {
      setIsUpdating(false);
      setErrorMessage(null);
    }
    if (statusResponse?.status === StatusEnum.ERROR) {
      setErrorMessage(
        statusResponse.message ||
          "An unknown error occurred while updating the dashboard",
      );
      setIsUpdating(false);
    }
    if (statusResponse?.status === StatusEnum.NOT_FOUND) {
      setIsUpdating(false);
    }
  }, [statusResponse]);

  if (isLoading) {
    return (
      <>
        <Text>Loading dashboard differences...</Text>
        <Loader />
      </>
    );
  }

  return (
    <>
      {isUpToDate && (
        <>
          <Text>The dashboard is up-to-date</Text>
        </>
      )}
      {!isUpToDate && (
        <>
          {data ? (
            <>
              <Text>
                Found differences between the dashboard in the database and the
                uploaded Excel file.
              </Text>
              <DashboardDifferencesTable data={data} />
            </>
          ) : (
            <Text>No data</Text>
          )}

          <Button
            onClick={() => {
              fetchDifferences();
              toast.info("Fetching differences...");
            }}
            loading={isValidating}
            variant="filled"
            color="gray"
            size="xs"
            mt="md"
          >
            Fetch Differences
          </Button>
          {isValidating && <Text c="dimmed">Fetching differences...</Text>}

          <Text fw={600} mt="md">
            Update the dashboard
          </Text>
          <Text>
            The dashboard will be updated with the changes made in the Excel
            file.
          </Text>
          {timeSeriesToDelete.length > 0 ? (
            <ConfirmationButton
              confirmationList={timeSeriesToDelete.map((ts) => ts.identifier)}
              onConfirm={(confirm) => {
                if (!confirm) return;
                handleUpdateDashboard();
              }}
              openButtonLabel="Update Dashboard"
              confirmLabel="Confirm Deletion and Update Dashboard"
              loading={isUpdating}
            />
          ) : (
            <Button onClick={handleUpdateDashboard} loading={isUpdating}>
              Update Dashboard
            </Button>
          )}
          {taskStatus && taskStatus !== StatusEnum.NOT_FOUND && (
            <>
              <span>
                <Badge
                  mt="xs"
                  color={statusToBadgeColor(taskStatus as StatusEnum)}
                  variant="light"
                >
                  {taskStatus}
                </Badge>
                {taskMessage && <Text ml="sm">{taskMessage}</Text>}
              </span>
              <ProgressBar progress={taskProgress} />
              <Button
                onClick={() => {
                  resetTaskStatus(apiClient, tenant_identifier);
                  toast.info("Resetting task status...");
                }}
                variant="filled"
                color="gray"
                size="xs"
                mt="md"
              >
                Reset Status
              </Button>
            </>
          )}
          {errorMessage && errorMessage !== taskMessage && (
            <Text c="red" ml="md" mt="sm">
              {errorMessage}
            </Text>
          )}
        </>
      )}
    </>
  );
};

export default UpdateDashboard;

const fetchDashboardDifferences = async (
  apiClient: BackendClient,
  tenant_identifier: string,
  sheetsData: SheetsData,
) => {
  const description = sheetsData.Description;
  const parameters = sheetsData.Parameter;
  const models = sheetsData.Model;
  const parts = sheetsData.Part;
  const hierarchy = sheetsData.Hierarchy;

  if (!description || !parameters || !models || !parts || !hierarchy) {
    console.error(
      "Description, parameters, models, parts, and hierarchy are required",
    );
    return;
  }
  try {
    const response =
      await apiClient.dashboard.differencesTenantsTenantIdDifferencesPost(
        tenant_identifier,
        {
          description,
          parameters,
          models,
          parts,
          hierarchy,
        },
      );
    return response;
  } catch (error) {
    throw error;
  }
};

const useDashboardDifferences = (
  apiClient: BackendClient,
  tenant_identifier: string,
  sheetsData: SheetsData,
) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    tenant_identifier ? ["dashboardDifferences", tenant_identifier] : null,
    () => fetchDashboardDifferences(apiClient, tenant_identifier, sheetsData),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return { data, error, isLoading, isValidating, fetchDifferences: mutate };
};

const fetchTaskStatus = async (
  apiClient: BackendClient,
  tenant_identifier: string,
) => {
  try {
    const response =
      await apiClient.dashboard.getTaskStatusTenantsTenantIdRebuildGet(
        tenant_identifier,
      );
    return response;
  } catch (error) {
    throw error;
  }
};

const useTaskStatus = (
  apiClient: BackendClient,
  tenant_identifier: string,
  shouldFetch = true,
) => {
  const {
    data: statusResponse,
    error,
    mutate,
  } = useSWR(
    shouldFetch ? ["taskStatus", tenant_identifier] : null,
    () => fetchTaskStatus(apiClient, tenant_identifier),
    { refreshInterval: 4_000 },
  );
  return { statusResponse, error, updateStatus: mutate };
};

const resetTaskStatus = async (
  apiClient: BackendClient,
  tenant_identifier: string,
) => {
  try {
    await apiClient.dashboard.resetTaskStatusTenantsTenantIdRebuildResetPost(
      tenant_identifier,
    );
  } catch (error) {
    throw error;
  }
};

const updateDashboardDifferences = async (
  apiClient: BackendClient,
  sheetsData: SheetsData,
  timeSeriesToDelete: string[],
  setErrorMessage: (message: string) => void,
  setIsUpdating: (updating: boolean) => void,
) => {
  try {
    const description = sheetsData.Description;
    const parameters = sheetsData.Parameter;
    const models = sheetsData.Model;
    const parts = sheetsData.Part;
    const hierarchy = sheetsData.Hierarchy;

    if (!description || !parameters || !models || !parts || !hierarchy) {
      throw new Error(
        "Description, parameters, models, parts, and hierarchy are required",
      );
    }
    setIsUpdating(true);
    await apiClient.dashboard.rebuildTenantsTenantIdRebuildPost({
      description,
      parameters,
      models,
      parts,
      hierarchy,
      timeseries_to_delete: timeSeriesToDelete,
    });
  } catch (error) {
    let errorMessage = "An unknown error occurred";
    if (error instanceof ApiError && error.body && error.body.detail) {
      errorMessage = error.body.detail;
    }
    setErrorMessage(errorMessage);
    setIsUpdating(false);
    toast.error("Failed to update dashboard.");
  }
};

const checkIfUpToDate = (data: DashboardDifferences | undefined) => {
  if (!data) return false;
  const { timeseries, datasources, tsprocessors, structuralcomponents } = data;

  // Timeseries updates
  const timeseriesUpdates = timeseries.update;
  const timeseriesEntitiesToCreate = timeseries.create;
  const timeseriesEntitiesToRemove = timeseries.remove;

  // Data sources updates
  const dataSourcesUpdates = datasources.update;
  const dataSourcesEntitiesToCreate = datasources.create;
  const dataSourcesEntitiesToRemove = datasources.remove;

  // Timeseries processors updates
  const timeseriesProcessorsUpdates = tsprocessors.update;
  const timeseriesProcessorsEntitiesToCreate = tsprocessors.create;
  const timeseriesProcessorsEntitiesToRemove = tsprocessors.remove;

  // Structural components updates
  const structuralComponentsUpdates = structuralcomponents.update;
  const structuralComponentsEntitiesToCreate = structuralcomponents.create;
  const structuralComponentsEntitiesToRemove = structuralcomponents.remove;

  // Check if there are any differences
  const isUpToDate =
    timeseriesUpdates.length === 0 &&
    timeseriesEntitiesToCreate.length === 0 &&
    timeseriesEntitiesToRemove.length === 0 &&
    dataSourcesUpdates.length === 0 &&
    dataSourcesEntitiesToCreate.length === 0 &&
    dataSourcesEntitiesToRemove.length === 0 &&
    timeseriesProcessorsUpdates.length === 0 &&
    timeseriesProcessorsEntitiesToCreate.length === 0 &&
    timeseriesProcessorsEntitiesToRemove.length === 0 &&
    structuralComponentsUpdates.length === 0 &&
    structuralComponentsEntitiesToCreate.length === 0 &&
    structuralComponentsEntitiesToRemove.length === 0;
  return isUpToDate;
};

const statusToBadgeColor = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.PENDING:
      return "gray";
    case StatusEnum.COMPLETED:
      return "green";
    case StatusEnum.ERROR:
      return "red";
    default:
      return "gray";
  }
};
