import { Anchor, Group, List, Text } from "@mantine/core";
import { useEffect } from "react";
import useEnvironmentStore from "../../../hooks/useEnvironmentStore";
import useExcelStore from "../../../hooks/useExcelStore";
import PickerButton from "../../Buttons/PickerButton";
import SheetStats from "../../Views/SheetStats";
import PickerRefreshButton from "../PickerRefreshButton";

const Picker = () => {
  const { name, path, isValid, validationMessages } = useExcelStore();
  const { setMaxActiveStep } = useEnvironmentStore();

  const validationText = validationMessages.map((message, index) => (
    <List.Item key={index}>{message}</List.Item>
  ));

  useEffect(() => {
    setMaxActiveStep(isValid);
  }, [isValid, setMaxActiveStep]);

  return (
    <>
      {path ? (
        <Text>
          You are working on{" "}
          <Anchor href={path} target="_blank">
            {name}
          </Anchor>
        </Text>
      ) : (
        <Text>You have not picked any file yet</Text>
      )}
      {path ? (
        <>
          <Group>
            <PickerRefreshButton>Refresh Excel File</PickerRefreshButton>
            <PickerButton>Change Excel File</PickerButton>
          </Group>
          <Text>To refresh the data:</Text>
          <List>
            <List.Item>
              Open the{" "}
              <Anchor href={path} target="_blank">
                <b>file</b>
              </Anchor>{" "}
              and make changes
            </List.Item>
            <List.Item>
              <b>Close the file</b> to save the changes to SharePoint
            </List.Item>
            <List.Item>Click the "Refresh Excel File" button</List.Item>
          </List>
        </>
      ) : (
        <PickerButton>Pick Excel File</PickerButton>
      )}
      <SheetStats />
      {!isValid && <List>{validationText}</List>}
    </>
  );
};

export default Picker;
