import {
  Button,
  CopyButton,
  Flex,
  Group,
  Paper,
  Table,
  Title,
} from "@mantine/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { useApiClient } from "../../../ApiClientProvider";
import useExcelStore from "../../../hooks/useExcelStore";

const ParameterClassifier = () => {
  const apiClient = useApiClient();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [classification, setClassification] = useState<string[]>([]);

  const { sheetsData } = useExcelStore();
  const opcuaNodeIds =
    sheetsData?.Parameter?.map((param) => param["OPC UA Node ID"]) || [];

  const classifyParameters = async () => {
    try {
      if (!opcuaNodeIds) throw new Error("No parameters to classify");
      setIsUpdating(true);
      const response =
        await apiClient.classifier.classifyParametersClassifierPost(
          opcuaNodeIds,
        );
      setClassification(response);
      toast.success("Parameters classified successfully!");
    } catch (error) {
      toast.error(`Failed to classify parameters: ${error}`);
    } finally {
      setIsUpdating(false);
    }
  };

  const rows = opcuaNodeIds.map((param, index) => (
    <Table.Tr key={param}>
      <Table.Td>{param}</Table.Td>
      <Table.Td>{classification[index]}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>Classify Parameters</Title>

        <Table stickyHeader striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Parameter</Table.Th>
              <Table.Th>Classification</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>

        <Group>
          <Button
            onClick={classifyParameters}
            loading={isUpdating}
            disabled={!opcuaNodeIds.length}
          >
            Classify
          </Button>

          <CopyButton value={classification.join("\n")}>
            {({ copied, copy }) => (
              <Button
                onClick={copy}
                disabled={!classification.length}
                variant="outline"
              >
                {copied ? "Copied" : "Copy Classification"}
              </Button>
            )}
          </CopyButton>
        </Group>
      </Flex>
    </Paper>
  );
};

export default ParameterClassifier;
