/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { aiomatic_warehouse__models__entity__structural_component__StructuralComponent__Reference } from "./aiomatic_warehouse__models__entity__structural_component__StructuralComponent__Reference";
import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from "./aiomatic_warehouse__models__entity__tenant__Tenant__Reference";
import type { ChartAssignment } from "./ChartAssignment";
import type { HealthAssignment } from "./HealthAssignment";
import type { NotificationSubscription } from "./NotificationSubscription";
import type { TSAState } from "./TSAState";
import type { WidgetAssignment } from "./WidgetAssignment";

export type StructuralComponent = {
  basetype?: StructuralComponent.basetype;
  id: string;
  identifier: string;
  tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
  parent?: aiomatic_warehouse__models__entity__structural_component__StructuralComponent__Reference;
  label: string;
  tsa_health?: HealthAssignment;
  tsa_widgets?: Array<WidgetAssignment>;
  tsa_charts?: Array<ChartAssignment>;
  tsa_states?: Array<TSAState>;
  notification_subscriptions?: Array<NotificationSubscription>;
};

export namespace StructuralComponent {
  export enum basetype {
    STRUCTURAL_COMPONENT = "StructuralComponent",
  }
}
