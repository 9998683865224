import PickerWrapped from "../../components/Controls/PickerWrapped";
import EdgeDeviceInfo from "../../components/EdgeManagement/EdgeDeviceInfo";
import TenantInfo from "../../components/TenantManagement/TenantInfo";
import TenantUsersInfo from "../../components/TenantManagement/TenantUsersInfo";
import DifferencesInfo from "../../components/Utility/DifferencesInfo";
import ParameterInfo from "../../components/Views/ParameterInfo";

const DifferencePage = () => {
  return (
    <>
      <PickerWrapped title="Difference: SharePoint and Database" />
      <DifferencesInfo />
      <TenantInfo />
      <TenantUsersInfo />
      <EdgeDeviceInfo />
      <ParameterInfo />
    </>
  );
};

export default DifferencePage;
