import { Flex, Loader, Paper, Text, Title } from "@mantine/core";
import useSWR from "swr";
import { useApiClient } from "../../../ApiClientProvider";
import {
  BackendClient,
  DatasourceData,
  DescriptionData,
  HierarchyData,
  ModelData,
  PartData,
} from "../../../generated";
import useExcelStore from "../../../hooks/useExcelStore";
import DashboardDifferencesTable from "../../Dashboard/DashboardDifferencesTable";

const fetchDashboardDifferences = async (
  apiClient: BackendClient,
  tenant_identifier: string,
  description: DescriptionData | undefined,
  parameters: DatasourceData[] | undefined,
  models: ModelData[] | undefined,
  parts: PartData[] | undefined,
  hierarchy: HierarchyData[] | undefined,
) => {
  if (!description || !parameters || !models || !parts || !hierarchy) {
    console.error(
      "Description, parameters, models, parts, and hierarchy are required",
    );
    return;
  }
  try {
    const response =
      await apiClient.dashboard.differencesTenantsTenantIdDifferencesPost(
        tenant_identifier,
        {
          description,
          parameters,
          models,
          parts,
          hierarchy,
        },
      );
    return response;
  } catch (error) {
    throw error;
  }
};

const DifferencesInfo = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const description = sheetsData.Description;
  const parameters = sheetsData.Parameter;
  const models = sheetsData.Model;
  const parts = sheetsData.Part;
  const hierarchy = sheetsData.Hierarchy;

  const tenant_identifier = description?.tenant_identifier || "";
  const cache_key = ["dashboard", tenant_identifier];

  const { data, error } = useSWR(
    tenant_identifier ? cache_key : null,
    () =>
      fetchDashboardDifferences(
        apiClient,
        tenant_identifier,
        description,
        parameters,
        models,
        parts,
        hierarchy,
      ),
    {
      shouldRetryOnError: false,
    },
  );
  console.log(error);
  if (!tenant_identifier) return null;

  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>Differences Between Excel and the Database</Title>

        <Text>
          This table shows the differences between the Excel file and the
          database. The <b>old_value</b> is the value in the database, and{" "}
          <b>new_value</b> is the value in the Excel file.
        </Text>

        {!data && !error && (
          <>
            <Text>Checking for differences...</Text>
            <Loader />
          </>
        )}
        {error && <Text>Couldn't fetch differences from the server.</Text>}
        {data && <DashboardDifferencesTable data={data} />}
      </Flex>
    </Paper>
  );
};

export default DifferencesInfo;
