import { useCallback, useEffect, useRef, useState } from "react";
import { read } from "xlsx";
import { fetchMsGraphExcel } from "../config/callMsGraphExcel";
import { AuthScope, useAuth } from "./useAuth";
import useExcelStore from "./useExcelStore";

const useExcel = (pickedFile: any) => {
  const [loading, setLoading] = useState(false);
  const { acquireToken } = useAuth(AuthScope.GraphAPI);
  const {
    pickedFile: previousPickedFile,
    setMetadata,
    setWorkbook,
    setPickedFile,
  } = useExcelStore();

  // Reference for the last fetched file ID
  const lastFetchedFileIdRef = useRef(null);

  const fetchData = useCallback(
    async (forceRefresh = false) => {
      if (!pickedFile) return;

      const fileId = pickedFile.items[0].id;

      // If the current file ID is the same as the last fetched one, return
      if (!forceRefresh && fileId === lastFetchedFileIdRef.current) return;
      if (!forceRefresh && previousPickedFile === pickedFile) return;

      const fileName = pickedFile.items[0].name;
      const filePath = pickedFile.items[0].webUrl;

      const token = await acquireToken();
      const file = await fetchMsGraphExcel(token, fileId);
      const workbook = read(file);

      setPickedFile(pickedFile);
      setWorkbook(workbook);
      setMetadata({
        id: fileId,
        name: fileName,
        path: filePath,
      });

      // Update the ref with the current file ID after fetching
      lastFetchedFileIdRef.current = fileId;
    },
    [
      pickedFile,
      previousPickedFile,
      acquireToken,
      setPickedFile,
      setWorkbook,
      setMetadata,
    ],
  );

  useEffect(() => {
    setLoading(true);
    fetchData()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [fetchData]);

  const refreshData = useCallback(() => {
    setLoading(true);
    fetchData(true)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [fetchData]);

  return { refreshData, loading };
};

export default useExcel;
