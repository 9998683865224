import { Badge } from "@mantine/core";
import React from "react";
import useEnvironmentStore from "../../../hooks/useEnvironmentStore";

const EnvironmentToggleButton: React.FC = () => {
  const { environment, color } = useEnvironmentStore();

  return (
    <Badge color={color}>
      {environment[0].toUpperCase() + environment.slice(1)}
    </Badge>
  );
};

export default EnvironmentToggleButton;
