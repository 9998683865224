/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenericResponse } from "../models/GenericResponse";
import type { StateResponse } from "../models/StateResponse";
import type { StateType } from "../models/StateType";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class StateService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get
   * Get information about a state with its associated structural components.
   * @param tenantId
   * @param stateId
   * @param xTenantId
   * @returns StateResponse Successful Response
   * @throws ApiError
   */
  public getTenantsTenantIdStatesStateIdGet(
    tenantId: string,
    stateId: string,
    xTenantId?: string,
  ): CancelablePromise<StateResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}/states/{state_id}",
      path: {
        tenant_id: tenantId,
        state_id: stateId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Upsert
   * Create or update a state for a tenant.
   * @param tenantId
   * @param stateId
   * @param stateColor
   * @param stateLabel
   * @param timeSeriesIdentifier
   * @param operator
   * @param threshold
   * @param scIdentifiers List of structural component identifiers
   * @param xTenantId
   * @returns GenericResponse Successful Response
   * @throws ApiError
   */
  public upsertTenantsTenantIdStatesStateIdPut(
    tenantId: string,
    stateId: string,
    stateColor: string,
    stateLabel: string,
    timeSeriesIdentifier?: string,
    operator?: string,
    threshold?: number,
    scIdentifiers?: Array<string>,
    xTenantId?: string,
  ): CancelablePromise<GenericResponse> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/tenants/{tenant_id}/states/{state_id}",
      path: {
        tenant_id: tenantId,
        state_id: stateId,
      },
      query: {
        state_color: stateColor,
        state_label: stateLabel,
        time_series_identifier: timeSeriesIdentifier,
        operator: operator,
        threshold: threshold,
        sc_identifiers: scIdentifiers,
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete
   * Delete a state for a tenant.
   * @param tenantId
   * @param stateId
   * @param xTenantId
   * @returns GenericResponse Successful Response
   * @throws ApiError
   */
  public deleteTenantsTenantIdStatesStateIdDelete(
    tenantId: string,
    stateId: string,
    xTenantId?: string,
  ): CancelablePromise<GenericResponse> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/tenants/{tenant_id}/states/{state_id}",
      path: {
        tenant_id: tenantId,
        state_id: stateId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get List
   * Get information about all states for a tenant.
   * @param tenantId
   * @param xTenantId
   * @returns StateType Successful Response
   * @throws ApiError
   */
  public getListTenantsTenantIdStatesGet(
    tenantId: string,
    xTenantId?: string,
  ): CancelablePromise<Array<StateType>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}/states",
      path: {
        tenant_id: tenantId,
      },
      query: {
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Assign State
   * Assign a state to structural components.
   * @param tenantId
   * @param stateId
   * @param timeseriesId
   * @param operator
   * @param threshold
   * @param scIdentifiers List of structural component identifiers
   * @param xTenantId
   * @returns any Successful Response
   * @throws ApiError
   */
  public assignStateTenantsTenantIdStatesStateIdAssignPost(
    tenantId: string,
    stateId: string,
    timeseriesId: string,
    operator: string,
    threshold: number,
    scIdentifiers: Array<string>,
    xTenantId?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/tenants/{tenant_id}/states/{state_id}/assign",
      path: {
        tenant_id: tenantId,
        state_id: stateId,
      },
      query: {
        timeseries_id: timeseriesId,
        operator: operator,
        threshold: threshold,
        sc_identifiers: scIdentifiers,
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Unassign State
   * Unassign a state from structural components.
   * @param tenantId
   * @param stateId
   * @param timeseriesId
   * @param operator
   * @param threshold
   * @param scIdentifiers List of structural component identifiers
   * @param xTenantId
   * @returns any Successful Response
   * @throws ApiError
   */
  public unassignStateTenantsTenantIdStatesStateIdUnassignDelete(
    tenantId: string,
    stateId: string,
    timeseriesId: string,
    operator: string,
    threshold: number,
    scIdentifiers: Array<string>,
    xTenantId?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/tenants/{tenant_id}/states/{state_id}/unassign",
      path: {
        tenant_id: tenantId,
        state_id: stateId,
      },
      query: {
        timeseries_id: timeseriesId,
        operator: operator,
        threshold: threshold,
        sc_identifiers: scIdentifiers,
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
