/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RuleProcessor = {
  basetype?: RuleProcessor.basetype;
  rule: string;
};

export namespace RuleProcessor {
  export enum basetype {
    RULE_PROC = "rule_proc",
  }
}
