/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Types of Sources that a timeseries can originate from
 */
export enum TsSourceType {
  ORIGIN = "Origin",
  DATA_SOURCE = "DataSource",
  ML_PROCESSOR = "MlProcessor",
  AGGREGATION_PROCESSOR = "AggregationProcessor",
  RULE_PROCESSOR = "RuleProcessor",
}
