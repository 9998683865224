/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AggregationProcessor } from "./AggregationProcessor";
import type { aiomatic_warehouse__models__entity__structural_component__StructuralComponent__Reference } from "./aiomatic_warehouse__models__entity__structural_component__StructuralComponent__Reference";
import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from "./aiomatic_warehouse__models__entity__tenant__Tenant__Reference";
import type { MlProcessor } from "./MlProcessor";
import type { ProcessorInput } from "./ProcessorInput";
import type { ProcessorOutput } from "./ProcessorOutput";
import type { RuleProcessor } from "./RuleProcessor";

export type TSProcessor = {
  basetype?: TSProcessor.basetype;
  id: string;
  identifier: string;
  tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
  input_specs: Array<ProcessorInput>;
  output_specs: Array<ProcessorOutput>;
  processor_specs: AggregationProcessor | MlProcessor | RuleProcessor;
  structural_components?: Array<aiomatic_warehouse__models__entity__structural_component__StructuralComponent__Reference>;
};

export namespace TSProcessor {
  export enum basetype {
    TS_PROCESSOR = "TsProcessor",
  }
}
