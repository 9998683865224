import { ScrollArea, Stack, Table } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import clsx from "clsx";
import React, { useState } from "react";
import { DashboardDifferences, EntityUpdate } from "../../../generated";
import EntityUpdatesTable from "../EntityUpdatesTable";
import classes from "./TableScrollArea.module.css";

interface DashboardDifferencesTableProps {
  data: DashboardDifferences;
}

const DashboardDifferencesTable: React.FC<DashboardDifferencesTableProps> = ({
  data,
}) => {
  const [visibleUpdate, setVisibleUpdate] = useState<string | null>(null);
  const [scrolled, setScrolled] = useState(false);

  const toggleDetails = (identifier: string) => {
    setVisibleUpdate(visibleUpdate === identifier ? null : identifier);
  };

  const renderUpdateDetails = (update: EntityUpdate) => (
    <div key={update.identifier} style={{ marginBottom: "10px" }}>
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => toggleDetails(update.identifier)}
      >
        {visibleUpdate === update.identifier ? (
          <IconChevronUp size={16} style={{ marginRight: "5px" }} />
        ) : (
          <IconChevronDown size={16} style={{ marginRight: "5px" }} />
        )}
        <span>{update.identifier}</span>
      </div>
      {visibleUpdate === update.identifier && (
        <div style={{ marginLeft: "20px", marginTop: "5px" }}>
          <EntityUpdatesTable updates={[update]} hideIdentifier />
        </div>
      )}
    </div>
  );

  return (
    <ScrollArea
      h={500}
      w="100%"
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
    >
      <Table stickyHeader striped>
        <Table.Thead
          className={clsx(classes.header, { [classes.scrolled]: scrolled })}
          style={{ marginBottom: "0" }}
        >
          <Table.Tr>
            <Table.Th>Type</Table.Th>
            <Table.Th>Delete</Table.Th>
            <Table.Th>Create</Table.Th>
            <Table.Th>Update</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {Object.entries(data).map(([EntityEnum, entityDifferences]) => (
            <Table.Tr key={EntityEnum}>
              <Table.Td style={{ verticalAlign: "top", fontWeight: "bold" }}>
                {EntityEnum}
              </Table.Td>
              <Table.Td style={{ verticalAlign: "top" }}>
                {entityDifferences.remove.length > 0 ? (
                  <Stack gap="xs">
                    {entityDifferences.remove.map((e) => (
                      <div>{e.identifier}</div>
                    ))}
                  </Stack>
                ) : (
                  "-"
                )}
              </Table.Td>
              <Table.Td style={{ verticalAlign: "top" }}>
                {entityDifferences.create.length > 0 ? (
                  <Stack gap="xs">
                    {entityDifferences.create.map((e) => (
                      <div>{e.identifier}</div>
                    ))}
                  </Stack>
                ) : (
                  "-"
                )}
              </Table.Td>
              <Table.Td style={{ verticalAlign: "top" }}>
                {entityDifferences.update.length > 0
                  ? entityDifferences.update.map((update) =>
                      renderUpdateDetails(update),
                    )
                  : "-"}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default DashboardDifferencesTable;
