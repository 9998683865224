import { Paper, ScrollArea, Table, Text, Title } from "@mantine/core";
import useExcelStore from "../../../hooks/useExcelStore";

const ExcelExclusionTable = () => {
  const { sheetsData } = useExcelStore();
  const exclusions = sheetsData.Exclusion;
  const formatDate = (date: string) => {
    return new Date(date)
      .toISOString()
      .replace("T", " ")
      .replace("Z", "")
      .slice(0, 19);
  };
  return (
    <Paper withBorder p="md" radius="md" mt="sm">
      <Title mb="md" order={3}>
        Exclusions Times in the Excel File (UTC)
      </Title>
      <ScrollArea>
        {exclusions && exclusions.length > 0 ? (
          <Table striped>
            <Table.Tbody>
              <Table.Tr>
                <Table.Th>Part Identifier</Table.Th>
                <Table.Th>Start</Table.Th>
                <Table.Th>End</Table.Th>
              </Table.Tr>
              {exclusions.map((exclusion, index) => (
                <Table.Tr key={index}>
                  <Table.Td>{exclusion.Identifier}</Table.Td>
                  <Table.Td>{formatDate(exclusion.Start)}</Table.Td>
                  <Table.Td>{formatDate(exclusion.End)}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        ) : (
          <Text>No exclusion data available.</Text>
        )}
      </ScrollArea>
    </Paper>
  );
};

export default ExcelExclusionTable;
