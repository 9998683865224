/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CombinedDataResponse } from "../models/CombinedDataResponse";
import type { EntityEnum } from "../models/EntityEnum";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class EntitiesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Entities
   * Get combined data for a tenant.
   * @param tenantId
   * @param include
   * @param xTenantId
   * @returns CombinedDataResponse Successful Response
   * @throws ApiError
   */
  public getEntitiesTenantsTenantIdEntitiesGet(
    tenantId: string,
    include: Array<EntityEnum>,
    xTenantId?: string,
  ): CancelablePromise<CombinedDataResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/tenants/{tenant_id}/entities",
      path: {
        tenant_id: tenantId,
      },
      query: {
        include: include,
        X_tenant_id: xTenantId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
