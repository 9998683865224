/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CauchyParams } from "./CauchyParams";
import type { ConditionerParamsDense } from "./ConditionerParamsDense";
import type { GammaNormalParams } from "./GammaNormalParams";

export type InitParams = {
  distribution?: InitParams.distribution;
  distribution_params?: GammaNormalParams | CauchyParams;
  conditioner?: InitParams.conditioner;
  conditioner_params?: ConditionerParamsDense;
};

export namespace InitParams {
  export enum distribution {
    NORMAL = "normal",
    GAMMA = "gamma",
    TWOPIECE_NORMAL = "twopiece_normal",
    GENERALIZED_NORMAL = "generalized_normal",
    CAUCHY = "cauchy",
  }

  export enum conditioner {
    DENSE = "dense",
  }
}
